<template>
  <div>
    <b-container style="text-align: center; " class="mt-2 pt-2">
      <b-row>
        <b-col>
          <h2 style="font-size: 18px !important; color: #625e5e;">
            PROGRAMME OUTCOME ATTAINMENT <span style="font-size: 18px !important; color: #625e5e;" v-if="usergroup">- {{usergroup.name}} - {{usergroup.programme_type.name}}</span>
          </h2>
        </b-col>
      </b-row>
    </b-container>
    <b-container class="mt-2">
      <b-row class="attainment" style="text-align:center;">
        <b-col md="12">
          <b-list-group horizontal>
            <b-list-group-item :active="componant == 'UsersubgroupDash'">
              {{subgroupCustomName}}
            </b-list-group-item>

            <b-list-group-item :active="componant == 'CourseDash'">
              Courses
            </b-list-group-item>

            <b-list-group-item :active="componant == 'PoPso'">
              PO & PSO
            </b-list-group-item>

            <b-list-group-item :active="componant == 'MappingDash'">
              CO-PO/PSO Mapping
            </b-list-group-item>

            <b-list-group-item :active="componant == 'ExitsurveyDash'">
              Exit Survey
            </b-list-group-item>

            <b-list-group-item :active="componant == 'AttainmentDash'">
              Attainment
            </b-list-group-item>
          </b-list-group>
        </b-col>
      </b-row>

      <b-row class="mt-2 pt-2">
        <b-col>
          <b-button @click="proceedToBack" :disabled="fetchData || componant == 'UsersubgroupDash'" pill
            variant="outline-secondary">Back</b-button></b-col>
        <b-col style="text-align:right;">
          <b-button :disabled="fetchData" @click="proceedToNext" pill variant="success">Proceed</b-button>
          <b-button @click="$router.push({ name: 'institute' })" variant="danger">Close</b-button>
        </b-col>
      </b-row>
      <b-container class="mt-2 pt-2">
        <usersubgroup-dash ref="UsersubgroupDash" :usersubgroupid="usersubgroupid"
          v-if="componant == 'UsersubgroupDash'"></usersubgroup-dash>
        <course-dash :usersubgroupid="usersubgroupid" ref="CourseDash" v-if="componant == 'CourseDash'"></course-dash>
        <po-pso :usersubgroupid="usersubgroupid" ref="PoPso" v-if="componant == 'PoPso'"></po-pso>
        <mapping-dash :usersubgroupid="usersubgroupid" ref="MappingDash"
          v-if="componant == 'MappingDash'"></mapping-dash>
        <exitsurvey-dash :usersubgroupid="usersubgroupid" ref="ExitsurveyDash"
          v-if="componant == 'ExitsurveyDash'"></exitsurvey-dash>
        <attainment-dash :usersubgroupid="usersubgroupid" :usersubgroupcode="usersubgroup.code" :usergroupcode="usergroup.code" ref="AttainmentDash"
          v-if="componant == 'AttainmentDash'"></attainment-dash>
      </b-container>
    </b-container>
  </div>
</template>

<script>
import UsersubgroupDash from "./usersubgroup.vue";
import CourseDash from "./course.vue";
import PoPso from "./popso.vue";
import MappingDash from "./copopsomapping.vue";
import ExitsurveyDash from "./poexitsurvey.vue";
import AttainmentDash from "./attainment.vue";
export default {
  // props: ["subgroupReport"],
  async created() {
    try {
      this.subgroupCustomName = this.$store.getters.getCustomNamings.userSubgroup.custom_name ?? "Usersubgroups";
    } catch (error) { error; }
    this.usersubgroupid = this.$route.params.usersubgroupId;
    this.getUsersubgroup();
    this.componant = "UsersubgroupDash";
  },
  components: {
    UsersubgroupDash: UsersubgroupDash,
    CourseDash: CourseDash,
    MappingDash: MappingDash,
    ExitsurveyDash: ExitsurveyDash,
    AttainmentDash: AttainmentDash,
    PoPso: PoPso,
  },
  data() {
    return {
      subgroupCustomName: 'Usersubgroups',
      fetchData: false,
      componant: "", // [usersubgroup,course,popso,mapping,exitsurvey,attainment]
      componants: [
        "UsersubgroupDash",
        "CourseDash",
        "PoPso",
        "MappingDash",
        "ExitsurveyDash",
        "AttainmentDash",
      ],
      usersubgroupid: this.$route.params.usersubgroupId,
      usersubgroup:{},
      usergroup:null,
    };
  },
  methods: {
    async proceedToNext() {
      // handle next tab
      const currentComponent = this.$refs[this.componant];
      // console.log(currentComponent);
      if (currentComponent.getReportFlag) {
        // finished and return
        if (this.componant == "AttainmentDash") return;

        // change conponant
        let currentindex = 0;
        await this.componants.forEach((componant, index) => {
          if (this.componant == componant) currentindex = index;
        });
        this.componant = this.componants[currentindex + 1];
      } else if (currentComponent.getDataFlag) {
        // call report
        currentComponent.getReport();
      } else if (currentComponent.setConfigFlag) {
        //call data
        currentComponent.getData();
      } else if (currentComponent.getConfigFlag) {
        // set config
        currentComponent.setConfig();
      } else {
        // somthing went wrong
      }
    },

    async proceedToBack() {
      // handle next tab
      const currentComponent = this.$refs[this.componant];
      let currentindex = 0;
      await this.componants.forEach((componant, index) => {
        if (this.componant == componant) currentindex = index;
      });
      this.componant = this.componants[currentindex - 1];




      if (currentComponent.getReportFlag) {
        // finished and return
        if (this.componant == "AttainmentDash") return;

        // change conponant
        let currentindex = 0;
        await this.componants.forEach((componant, index) => {
          if (this.componant == componant) currentindex = index;
        });
        this.componant = this.componants[currentindex + 1];
      } else if (currentComponent.getDataFlag) {
        // call report
        currentComponent.getReport();
      } else if (currentComponent.setConfigFlag) {
        //call data
        currentComponent.getData();
      } else if (currentComponent.getConfigFlag) {
        // set config
        currentComponent.setConfig();
      } else {
        // somthing went wrong
      }
    },
    async getUsersubgroup(){
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/usersubgroups/${this.usersubgroupid}`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.usersubgroup = response.data;
          this.getUsergroup();
        })
        .catch((error) => {
          error;
        });
    },
    async getUsergroup(){
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/usergroups/${this.usersubgroup.usergroup_id}`;
      await this.$axios
        .get(url)
        .then((response) => {
          this.usergroup = response.data;
        })
        .catch((error) => {
          error;
        });
    },

    // async doanloadresultanalysis() {
    //   this.loadReport = true;

    //   const url =
    //     this.$store.getters.getAPIKey.mainAPI +
    //     `/boardexams/usersubgroup/resultanalysis`;
    //   await this.$axios
    //     .post(
    //       url,
    //       {
    //         usersubgroup: this.subgroupReport.id,
    //         courses: this.selectedCourses,
    //       },
    //       { responseType: "blob" }
    //     )
    //     .then((response) => {
    //       const url = window.URL.createObjectURL(new Blob([response.data]));
    //       const link = document.createElement("a");
    //       link.href = url;
    //       link.setAttribute("download", "resultanalysis.pdf");
    //       document.body.appendChild(link);
    //       link.click();
    //       link.remove();

    //       this.loadReport = false;
    //     })
    //     .catch((error) => {
    //       error.response.data.text().then((text) => {
    //         const res = JSON.parse(text);
    //         this.$toast.error(res.message, {
    //           position: "top",
    //           duration: 3000,
    //         });
    //       });
    //       this.loadReport = false;
    //     });
    //   this.loadReport = false;
    // },
  },
};
</script>

<style >
.attainment .list-group .list-group-item {
  font-family: "Roboto Regular";
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  font-size: 14px;
  font-weight: 600;
}
</style>
