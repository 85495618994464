<template>
  <div>
    <b-row>
      <b-col v-if="fetchData" style="font-size: 13px !important; color: #red; text-align:center;">
        working on ...
      </b-col>
    </b-row>

    <b-row v-if="!fetchData">
      <b-col v-if="(getConfigFlag && !setConfigFlag)">
        Exit surveys.
        <div class="table-responsive">
          <table class="table table-bordered table-striped">
            <thead class="thead-dark text-left">
              <tr>
                <td></td>
                <td></td>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(survey, courseIndex) in surveys" :key="courseIndex">
                <td class="align-middle">
                  <b-form-radio v-model="survey_id" name="some-radios" :value="survey.id"></b-form-radio>
                </td>
                <td class="align-middle">
                  {{ survey.name }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-col>
      <b-col v-if="(getDataFlag)">
        Exit survey data
        <p class="text-danger">Questions that are not linked with PO/PSO will be ignored</p>
        <div class="table-responsive">
          <table class="table table-bordered table-striped">
            <thead class="thead-dark text-left">
              <tr>
                <th>Q No.</th>
                <th>Question</th>
                <th>PO/PSO</th>
                <th>Choices</th>
                <th>Count</th>
                <th>Avg. Score</th>
                <th>%</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(question, qIndex) in exit_survey_questions" :key="qIndex">
                <td>{{ question.question_no }}</td>
                <td>{{ question.name }}</td>
                <td>
                  <span v-for="(po, poIndex) in question.programme_outcomes" :key="poIndex">
                    {{ programme_outcomes[po].code }},</span>
                  <span v-for="(pso, psoIndex) in question.programme_specific_outcomes" :key="psoIndex">
                    {{ programme_specific_outcomes[pso].code }},</span>
                </td>

                <td>
                  <span v-for="(choice, cIndex) in question.choices" :key="cIndex">{{ choice.name }}({{ choice.score }})
                    <br></span>
                </td>
                <td>
                  <span v-for="(choice, cIndex) in question.choices" :key="cIndex">{{
                      questionAnswerSummery[question.id].choices[choice.id].length
                  }} <br></span>
                </td>
                <td>{{ Math.round(questionAnswerSummery[question.id].avg_score * 100) / 100 }}</td>
                <td>{{ Math.round(questionAnswerSummery[question.id].persantage * 100) / 100 }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-col>
      <b-col v-if="getReportFlag">
        Exit survey report
        <div class="table-responsive">
          <table class="table table-bordered table-striped">
            <thead class="thead-dark text-left">
              <tr>
                <th>PO/PSO</th>
                <th v-for="(po, poIndex) in programme_outcomes" :key="'po1' + poIndex">
                  {{ po.code }}</th>
                <th v-for="(pso, psoIndex) in programme_specific_outcomes" :key="'pso1' + psoIndex">
                  {{ pso.code }}</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Avg. Score</th>

                <td v-for="(po, poIndex) in programme_outcomes" :key="'po' + poIndex">
                  {{ Math.round(po_avg_scores[po.id] * 100) / 100 }}</td>
                <td v-for="(pso, psoIndex) in programme_specific_outcomes" :key="'pso' + psoIndex">
                  {{ Math.round(pso_avg_scores[pso.id] * 100) / 100 }}</td>
              </tr>
              <tr>
                <th>Attainment. Score out of 3</th>

                <td v-for="(po, poIndex) in programme_outcomes" :key="'po' + poIndex">
                  {{ Math.round(po_attainment_scores[po.id] * 100) / 100 }}</td>
                <td v-for="(pso, psoIndex) in programme_specific_outcomes" :key="'pso' + psoIndex">
                  {{ Math.round(pso_attainment_scores[pso.id] * 100) / 100 }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-col>

    </b-row>
  </div>
</template>

<script>
// import DatePicker from "vue2-datepicker";
export default {
  props: ["usersubgroupid"],
  async created() {
    this.getConfig();
  },
  components: {
    // DatePicker,
  },
  data() {
    return {
      getConfigFlag: true,
      setConfigFlag: false,
      getDataFlag: false,
      getReportFlag: false,

      fetchData: false,
      surveys: [],
      survey_id: null,

      //
      exit_survey_questions: {},
      paricipants_count: null,
      po_avg_scores: {},
      po_attainment_scores: {},
      po_questions_ids: {},
      pso_avg_scores: {},
      pso_attainment_scores: {},
      pso_questions_ids: {},
      questionAnswerSummery: {},

      programme_specific_outcomes: [],
      programme_outcomes: [],
    };
  },
  methods: {
    async getConfig() {
      this.fetchData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/poattainment/getconfig`;
      await this.$axios
        .post(url, {
          subgroup: this.usersubgroupid,
          step: "programmeexitsurvey",
          survey_id: this.survey_id,
        })
        .then((response) => {
          this.surveys = response.data;
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message, {
            position: "top",
            duration: 3000,
          });
        });
      this.fetchData = false;
    },
    async setConfig() {
      this.fetchData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/poattainment/setconfig`;
      await this.$axios
        .post(url, {
          subgroup: this.usersubgroupid,
          survey_id: this.survey_id,
          step: "programmeexitsurvey",
        })
        .then((response) => {
          response;
          this.setConfigFlag = true;
          this.getData();
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message, {
            position: "top",
            duration: 3000,
          });
        });
    },
    async getData() {
      this.fetchData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/poattainment/getdata`;
      await this.$axios
        .post(url, {
          subgroup: this.usersubgroupid,
          step: "programmeexitsurvey",
        })
        .then((response) => {
          let res = response.data;
          this.exit_survey_questions = res.exit_survey_questions;
          this.paricipants_count = res.paricipants_count;
          // this.po_avg_scores=res.po_avg_scores ;
          // this.po_questions_ids=res.po_questions_ids ;
          // this.pso_avg_scores=res.pso_avg_scores ;
          // this.pso_questions_ids=res.pso_questions_ids ;
          this.questionAnswerSummery = res.questionAnswerSummery;
          this.programme_specific_outcomes = res.programme_specific_outcomes;
          this.programme_outcomes = res.programme_outcomes;
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message, {
            position: "top",
            duration: 3000,
          });
        });
      this.getDataFlag = true;
      this.fetchData = false;
    },
    async getReport() {
      this.fetchData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/poattainment/report`;
      await this.$axios
        .post(url, {
          subgroup: this.usersubgroupid,
          step: "programmeexitsurvey",
        })
        .then((response) => {
          let res = response.data;
          // this.exit_survey_questions = res.exit_survey_questions;
          // this.paricipants_count = res.paricipants_count;
          this.po_avg_scores = res.po_avg_scores;
          this.po_attainment_scores = res.po_attainment_scores;
          this.po_questions_ids = res.po_questions_ids;
          this.pso_avg_scores = res.pso_avg_scores;
          this.pso_attainment_scores = res.pso_attainment_scores;
          this.pso_questions_ids = res.pso_questions_ids;
          // this.questionAnswerSummery = res.questionAnswerSummery;
          // this.programme_specific_outcomes = res.programme_specific_outcomes;
          // this.programme_outcomes = res.programme_outcomes;
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message, {
            position: "top",
            duration: 3000,
          });
        });

      this.getReportFlag = true;
      this.fetchData = false;
    },
    // selectAllSubgroups() {},
  },
};
</script>
