<template>
  <div>
    <b-row>
      <b-col
        v-if="fetchData"
        style="font-size: 13px !important; color: #red; text-align: center"
      >
        working on ...
      </b-col>
    </b-row>

    <b-row v-if="!fetchData">
     <!--<b-col v-if="getConfigFlag && !setConfigFlag">
        Exit surveys.
        <div class="table-responsive">
          <table class="table table-bordered table-striped">
            <thead class="thead-dark text-left">
              <tr>
                <td>Direct method weightage</td>
                <td>Indirect method weightage</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="align-middle">
                  <b-form-input
                    v-model="directmethod_weightage"
                    style="width: 70px"
                    type="number"
                    @blur="checkWeigtage('direct')"
                  ></b-form-input>
                </td>
                <td class="align-middle">
                  <b-form-input
                    v-model="indirectmethod_weightage"
                    style="width: 70px"
                    type="number"
                    @blur="checkWeigtage('indirect')"
                  ></b-form-input>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-col>--> 
      <b-col v-if="getReportFlag">
        Attainment report
        <div class="table-responsive">
          <table class="table table-bordered table-striped">
            <thead class="thead-dark text-left">
              <tr>
                <th>PO/PSO</th>
                <th
                  v-for="(po, poIndex) in programme_outcomes"
                  :key="'po' + poIndex"
                >
                  {{ po.code }}
                </th>
                <th
                  v-for="(pso, psoIndex) in programme_specific_outcomes"
                  :key="'pso' + psoIndex"
                >
                  {{ pso.code }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>PO Direct Attainment Value</th>

                <td
                  v-for="(po, poIndex) in programme_outcomes"
                  :key="'poms' + poIndex"
                >
                <span v-if="po_pso_avg_mapping_scores.po_avg[po.id] >0">
                  {{
                    Math.round(po_pso_avg_mapping_scores.po_avg[po.id] * 100) /
                    100
                  }}
                  </span>
                  <span v-else>
                    -
                  </span>
                </td>
                <td
                  v-for="(pso, psoIndex) in programme_specific_outcomes"
                  :key="'psomc' + psoIndex"
                >
                <span v-if="po_pso_avg_mapping_scores.pso_avg[pso.id] >0">
                  {{
                    Math.round(
                      po_pso_avg_mapping_scores.pso_avg[pso.id] * 100
                    ) / 100
                  }}
                  </span>
                  <span v-else>
                    -
                  </span>
                </td>
              </tr>
              <tr>
                <th>PO Indirect Attainment Value</th>

                <td
                  v-for="(po, poIndex) in programme_outcomes"
                  :key="'poes' + poIndex"
                >
                <span v-if="exitsurvey_po_scores[po.id] >0">
                  {{ Math.round(exitsurvey_po_scores[po.id] * 100) / 100 }}
                </span>
                <span v-else>
                  -
                </span>
                </td>
                <td
                  v-for="(pso, psoIndex) in programme_specific_outcomes"
                  :key="'psoes' + psoIndex"
                >
                <span v-if="exitsurvey_pso_scores[pso.id] >0">
                  {{ Math.round(exitsurvey_pso_scores[pso.id] * 100) / 100 }}
                </span>
                <span v-else>
                  -
                </span>
                </td>
              </tr>
              <tr>
                <th>PO Final Attainment Value</th>
                <td
                  v-for="(po, poIndex) in programme_outcomes"
                  :key="'poa' + poIndex"
                >
                <span v-if="poAttainmentValue[po.id] >0">
                  {{ Math.round(poAttainmentValue[po.id] * 100) / 100 }}
                </span>
                <span v-else>
                  -
                </span>
                </td>
                <td
                  v-for="(pso, psoIndex) in programme_specific_outcomes"
                  :key="'psoa' + psoIndex"
                >
                <span v-if="psoAttainmentValue[pso.id] >0">
                  {{ Math.round(psoAttainmentValue[pso.id] * 100) / 100 }}
                </span>
                <span v-else>
                  -
                </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />

        <b-row>
          <b-col>
            <div class="text-center m-2">
              <b-button
                @click="saveAttainmentConfigs()"
                variant="success"
                size="sm"
                :disabled="fetchData"
                >Save selection and configuration for auditor report
                view</b-button
              >
            </div>
          </b-col>
        </b-row>

        Download report
        <div class="table-responsive">
          <table class="table table-bordered table-striped">
            <thead class="thead-dark text-left">
              <tr>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Programme CO Attainment Report</td>
                <td>
                  <b-button
                    :disabled="fetchData"
                    @click="downloadAttainmentReport('course', false)"
                    variant="primary"
                    size="sm"
                    >Download Report</b-button
                  >
                  <b-button
                    style="margin-left: 1px"
                    :disabled="fetchData"
                    @click="downloadAttainmentReport('course', true)"
                    variant="info"
                    size="sm"
                    >View Report</b-button
                  >
                </td>
              </tr>

              <tr>
                <td>PO Direct Attainment Report</td>
                <td>
                  <b-button
                    :disabled="fetchData"
                    @click="downloadAttainmentReport('co_po_pso_mapping', false)"
                    variant="primary"
                    size="sm"
                    >Download Report</b-button
                  >
                  <b-button
                    style="margin-left: 1px"
                    :disabled="fetchData"
                    @click="downloadAttainmentReport('co_po_pso_mapping', true)"
                    variant="info"
                    size="sm"
                    >View Report</b-button
                  >
                </td>
              </tr>

              <tr>
                <td>PO Indirect Attainment Report</td>
                <td>
                  <b-button
                    :disabled="fetchData"
                    @click="
                      downloadAttainmentReport('programmeexitsurvey', false)
                    "
                    variant="primary"
                    size="sm"
                    >Download Report</b-button
                  >
                  <b-button
                    style="margin-left: 1px"
                    :disabled="fetchData"
                    @click="
                      downloadAttainmentReport('programmeexitsurvey', true)
                    "
                    variant="info"
                    size="sm"
                    >View Report</b-button
                  >
                </td>
              </tr>

              <tr>
                <td>Programme PO attainment Report</td>
                <td>
                  <b-button
                    :disabled="fetchData"
                    @click="downloadAttainmentReport('attainment', false)"
                    variant="primary"
                    size="sm"
                    >Download Report</b-button
                  >
                  <b-button
                    style="margin-left: 1px"
                    :disabled="fetchData"
                    @click="downloadAttainmentReport('attainment', true)"
                    variant="info"
                    size="sm"
                    >View Report</b-button
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// import DatePicker from "vue2-datepicker";
export default {
  props: ["usersubgroupid","usersubgroupcode","usergroupcode"],
  async created() {
    this.getReport();
  },
  components: {
    // DatePicker,
  },
  data() {
    return {
      getConfigFlag: true,
      setConfigFlag: false,
      getDataFlag: false,
      getReportFlag: false,

      fetchData: false,
      
      programme_specific_outcomes: {},
      programme_outcomes: {},
      poAttainmentValue: {},
      psoAttainmentValue: {},
      exitsurvey_po_scores: {},
      exitsurvey_pso_scores: {},
      po_pso_avg_mapping_scores: {},

      directmethod_weightage: 80,
      indirectmethod_weightage: 20,
    };
  },
  methods: {
    async saveAttainmentConfigs() {
      this.fetchData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/poattainment/savedata`;
      await this.$axios
        .post(url, { subgroup: this.usersubgroupid })
        .then(() => {
          this.$toast.success("Configuration saved successfully", {
            position: "top",
            duration: 3000,
          });
          this.fetchData = false;
        })
        .catch(() => {
          this.fetchData = false;
          this.$toast.error("Failed save configuration", {
            position: "top",
            duration: 5000,
          });
        });
    },
    async downloadAttainmentReport(step, viewReport) {
      this.fetchData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI +
        `/poattainment/report/pdfexport`;
      await this.$axios
        .post(
          url,
          {
            subgroup: this.usersubgroupid,
            step: step,
          },
          { responseType: "blob" }
        )
        .then((response) => {
          const pdfBlob = new Blob([response.data], {
            type: "application/pdf",
          });
          const pdfUrl = window.URL.createObjectURL(pdfBlob);
          let newTab = null;
          if (viewReport) {
            newTab = window.open(pdfUrl, "_blank");
          }
          if (!newTab) {
            if (viewReport)
              this.$toast.error(
                "Failed to show pdf file, insted the file will be downloaded",
                {
                  position: "top",
                  duration: 3000,
                }
              );
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            if(step == 'course'){
                link.setAttribute("download",`Course - Course Outcomes-${this.usersubgroupcode}-${this.usergroupcode}.pdf`);
            }
            else if(step == 'co_po_pso_mapping'){
                link.setAttribute("download",`CO PO PSO Mapping-${this.usersubgroupcode}-${this.usergroupcode}.pdf`);
            }
            else if(step == 'programmeexitsurvey'){
                link.setAttribute("download",`Exit survey-${this.usersubgroupcode}-${this.usergroupcode}.pdf`);
            }
            else{
                link.setAttribute("download",`PO attainment-${this.usersubgroupcode}-${this.usergroupcode}.pdf`);
            }  
            document.body.appendChild(link);
            link.click();
            link.remove();
          }
          this.fetchData = false;
        })
        .catch((error) => {
          this.fetchData = false;
          if (error.response) {
            this.$toast.error(error.response.data.message, {
              position: "top",
              duration: 3000,
            });
          } else {
            this.$toast.error("Failed to download", {
              position: "top",
              duration: 3000,
            });
          }
          // this.downloadData = false;
        });
    },
    async getReport() {
      this.fetchData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/poattainment/report`;
      await this.$axios
        .post(url, {
          subgroup: this.usersubgroupid,
          step: "attainment",
        })
        .then((response) => {
          let res = response.data;
          this.programme_specific_outcomes = res.programme_specific_outcomes;
          this.programme_outcomes = res.programme_outcomes;
          this.poAttainmentValue = res.poAttainmentValue;
          this.psoAttainmentValue = res.psoAttainmentValue;
          this.exitsurvey_po_scores = res.exitsurvey_po_scores;
          this.exitsurvey_pso_scores = res.exitsurvey_pso_scores;
          this.po_pso_avg_mapping_scores = res.po_pso_avg_mapping_scores;
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message, {
            position: "top",
            duration: 3000,
          });
        });

      this.getReportFlag = true;
      this.fetchData = false;
    },
    // selectAllSubgroups() {},
  },
};
</script>
