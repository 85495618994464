<template>
  <div>
    <b-row>
      <b-col v-if="fetchData" style="font-size: 13px !important; color: red; text-align:center;">
        working on ...
      </b-col>
    </b-row>
    <b-row v-if="!fetchData">
      <b-col v-if="!setConfigFlag">
        Course configs.

        <div class="table-responsive">
          <table class="table table-bordered table-striped">
            <thead class="thead-dark text-left">
              <tr>
                <th>
                  <b-form-checkbox @change="selectAllCourse" v-model="selectAllCourseCheckbox">{{
                      selectAllCourseCheckbox
                        ? "Unselect All"
                        : "Select All"
                  }}</b-form-checkbox>
                </th>
                <th>Course</th>
                <th>Academic Year</th>
                <th>{{ subgroupCustomName }}</th>
                <th>CO Attainment</th>
              </tr>
            </thead>
            <tbody>
              <tr
                :class="{ 'align-middle': true, 'text-success': course.attainment_generated}"
                v-for="(course, courseIndex) in courses" :key="courseIndex">
                <td class="align-middle">
                  <b-form-checkbox :disabled="!course.attainment_generated" size="sm"
                    v-model="courses[courseIndex].checked">
                  </b-form-checkbox>
                </td>
                <td>
                  {{ course.name }} {{ course.code }}
                </td>
                <td>
                  {{ course.academic_year }}-{{ course.academic_semester }}
                </td>
                <td>
                  {{ course.usersubgroup.name }} {{ course.usersubgroup.code }}
                </td>
                <td class="align-middle" :class="{'text-danger': !course.attainment_generated }">
                  {{ course.attainment_generated ? '✓' : 'Not Generated' }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-col>
      <b-col v-if="setConfigFlag">
        Confirm selection

        <div class="table-responsive">
          <table class="table table-bordered table-striped">
            <thead class="thead-dark text-left">
              <tr>
                <th>Course</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(course, courseIndex) in courses" :key="courseIndex">
                <td v-if="course.checked">
                  {{ course.name }} {{ course.code }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// import DatePicker from "vue2-datepicker";
export default {
  props: ["usersubgroupid"],
  async created() {
    try {
      this.subgroupCustomName = this.$store.getters.getCustomNamings.userSubgroup.custom_name ?? "Usersubgroup";
    } catch (error) { error; }
    this.fetchData = true;
    await this.getConfig();
    this.fetchData = false;
  },
  components: {
    // DatePicker,
  },
  data() {
    return {
      subgroupCustomName: 'Usersubgroup',
      getConfigFlag: true,
      setConfigFlag: false,
      getDataFlag: false,
      getReportFlag: false,

      fetchData: false,
      courses: [],
      selectAllCourseCheckbox: false,
    };
  },
  methods: {
    selectAllCourse() {
      this.courses.forEach((course) => {
        course.checked = this.selectAllCourseCheckbox ? course.attainment_generated : false;
      });
      this.$forceUpdate();
    },
    async getConfig() {
      this.fetchData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/poattainment/getconfig`;
      await this.$axios
        .post(url, {
          subgroup: this.usersubgroupid,
          step: "course",
        })
        .then((response) => {
          this.courses = response.data;
          this.courses.forEach((course) => {
            course.checked = false;
          });
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message, {
            position: "top",
            duration: 3000,
          });
        });
      this.fetchData = false;
    },
    async setConfig() {
      this.fetchData = true;
      let courses = [];
      await this.courses.forEach((course) => {
        if (course.checked) courses.push(course.id);
      });
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/poattainment/setconfig`;
      await this.$axios
        .post(url, {
          subgroup: this.usersubgroupid,
          step: "course",
          courses: courses,
        })
        .then((response) => {
          response;
          this.setConfigFlag = true;
          this.getDataFlag = true;
          this.getReportFlag = true;
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message, {
            position: "top",
            duration: 3000,
          });
        });
      this.fetchData = false;
    },
    // getData() {
    //   const url =
    //     this.$store.getters.getAPIKey.mainAPI + `/poattainment/getdata`;
    //   this.$axios
    //     .post(url, {
    //       subgroup: this.usersubgroupid,
    //       step: "course",
    //     })
    //     .then((response) => {
    //       this.assementsArray = response.data;
    //     })
    //     .catch((error) => {
    //       this.$toast.error(error.response.data.message, {
    //         position: "top",
    //         duration: 3000,
    //       });
    //     });
    // },
    // getReport() {
    //   const url =
    //     this.$store.getters.getAPIKey.mainAPI + `/poattainment/report`;
    //   this.$axios
    //     .post(url, {
    //       subgroup: this.usersubgroupid,
    //       step: "course",
    //     })
    //     .then((response) => {
    //       this.assementsArray = response.data;
    //     })
    //     .catch((error) => {
    //       this.$toast.error(error.response.data.message, {
    //         position: "top",
    //         duration: 3000,
    //       });
    //     });
    // },
    // selectAllSubgroups() {},
  },
};
</script>
