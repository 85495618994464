<template>
  <div>
    <b-row>
      <b-col v-if="fetchData" style="font-size: 13px !important; color: red; text-align:center;">
        working on ...
      </b-col>
    </b-row>
    <b-row v-if="!fetchData">
      <b-col v-if="!setConfigFlag">
        <!-- <b-badge class="m-1" v-for="(usersubgroup, subgroupIndex) in selectedGroups" :key="subgroupIndex"
          variant="secondary">{{
              usersubgroup.code
          }}</b-badge>
        <br> -->

        <div class="table-responsive">

          <b-form-group label="Choose Academic Year:" v-slot="{ ariaDescribedby }">
            <b-form-checkbox-group id="checkbox-group-2" v-model="academic_years" :aria-describedby="ariaDescribedby"
              name="flavour-2">
              <span v-for="academicyear in $acedemicYears" :key="academicyear.value">
              <b-form-checkbox v-if="academicyear.value" :value="academicyear.value">{{ academicyear.text }}</b-form-checkbox></span>
            </b-form-checkbox-group>
            <!-- <b-form-checkbox-group id="checkbox-group-1" v-model="academic_year" :options="acedemicYearsOptions"
              :aria-describedby="ariaDescribedby" name="flavour-1"></b-form-checkbox-group> -->
          </b-form-group>

          <table class="table table-bordered table-striped">
            <thead class="thead-dark text-left">
              <tr>
                <td v-if="(academic_years.length < 1)" class="text-danger">Please Choose Academic Years</td>
                <th v-for="academicyear in academic_years" :key="academicyear">{{academicyear}}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td v-for="academicyear in academic_years" :key="academicyear">
                  <span v-for="(usersubgroup, subgroupIndex) in filteredsubgroups" :key="subgroupIndex">
                    <div v-if="(usersubgroup.academic_year == academicyear)">
                      <b-form-checkbox size="sm" v-model="selected_group_ids" :value="usersubgroup.id"
                    @change="updateGroupSelection">{{ usersubgroup.name }} {{ usersubgroup.code }} {{ usersubgroup.academic_year }}-{{ usersubgroup.academic_semester }}
                  </b-form-checkbox>  <br>
                    </div>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-col>

      <b-col v-if="setConfigFlag">
        Confirm selection:
        <br>
        <b-badge class="m-1" v-for="(usersubgroup, subgroupIndex) in selectedGroups" :key="subgroupIndex"
          variant="secondary">{{
    usersubgroup.code
          }}</b-badge>
      </b-col>

    </b-row>
  </div>
</template>

<script>
// import DatePicker from "vue2-datepicker";
export default {
  props: ["usersubgroupid"],
  async created() {
    // console.log(this.$acedemicYears);
    // this.acedemicYearsOptions = this.$acedemicYears;
    // this.acedemicYearsOptions.splice(0, 1);
    // this.acedemicYearsOptions[0]={ value: '', text: "All academic years" };
    this.fetchData = true;
    await this.getConfig();
    this.fetchData = false;
  },
  components: {
    // DatePicker,
  },
  data() {
    return {
      getConfigFlag: true,
      setConfigFlag: false,
      getDataFlag: false,
      getReportFlag: false,

      fetchData: false,
      acedemicYearsOptions: [],
      academic_years: [],
      usersubgroups: [], // groups
      selected_group_ids: [], // groups
      // selectAllSubgroupsCheckbox: false,
    };
  },
  computed: {
    filteredsubgroups() {
      this.academic_years;
      return this.usersubgroups.filter((group) => {
        return this.academic_years.length < 1 ? true : this.academic_years.includes(`${group.academic_year}`);
      });
    },
    selectedGroups() {
      this.selected_group_ids;
      return this.usersubgroups.filter((group) => {
        return this.selected_group_ids.includes(group.id);
      });
    },
  },
  methods: {
    updateGroupSelection() {
      this.$forceUpdate();
    },
    async getConfig() {
      this.fetchData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/poattainment/getconfig`;
      await this.$axios
        .post(url, {
          subgroup: this.usersubgroupid,
          step: "usersubgroups",
          // academic_year: this.academic_year,
        })
        .then((response) => {
          this.usersubgroups = response.data;
          // this.usersubgroups.forEach((usersubgroup) => {
          //   usersubgroup.checked = false;
          // });
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message, {
            position: "top",
            duration: 3000,
          });
        });
      this.fetchData = false;
    },
    async setConfig() {
      this.fetchData = true;
      // let usersubgroups = [];
      // await this.usersubgroups.forEach((usersubgroup) => {
      //   if (usersubgroup.checked) usersubgroups.push(usersubgroup.id);
      // });

      const url =
        this.$store.getters.getAPIKey.mainAPI + `/poattainment/setconfig`;
      await this.$axios
        .post(url, {
          subgroup: this.usersubgroupid,
          step: "usersubgroups",
          usersubgroups: this.selected_group_ids,
        })
        .then((response) => {
          response;
          this.setConfigFlag = true;
          this.getDataFlag = true;
          this.getReportFlag = true;
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message, {
            position: "top",
            duration: 3000,
          });
        });
      this.fetchData = false;
    },
    // getData() {
    //   const url =
    //     this.$store.getters.getAPIKey.mainAPI + `/poattainment/getdata`;
    //   this.$axios
    //     .post(url, {
    //       subgroup: this.usersubgroupid,
    //       step: "usersubgroups",
    //     })
    //     .then((response) => {
    //       this.assementsArray = response.data;
    //     });
    // },
    // getReport() {
    //   const url =
    //     this.$store.getters.getAPIKey.mainAPI + `/poattainment/report`;
    //   this.$axios
    //     .post(url, {
    //       subgroup: this.usersubgroupid,
    //       step: "usersubgroups",
    //     })
    //     .then((response) => {
    //       this.assementsArray = response.data;
    //     });
    // },
    // selectAllSubgroups() {},
  },
};
</script>
