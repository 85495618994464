<template>
  <div>
    <b-row>
      <b-col v-if="fetchData" style="font-size: 13px !important; color: #red; text-align:center;">
        working on ...
      </b-col>
    </b-row>

    <b-row v-if="!fetchData">
      <b-col v-if="(getDataFlag)">
        CO PO PSO Mapping.

        <div class="table-responsive">
          <table class="table table-bordered table-striped">
            <thead class="thead-dark text-left">
              <tr>
                <th rowspan="2">Group</th>
                <th rowspan="2">Course</th>
                <th rowspan="2">CO</th>
                <th :colspan="Object.keys(programme_outcomes).length">PO</th>
                <th :colspan="Object.keys(programme_specific_outcomes).length">PSO</th>
              </tr>
              <tr>
                <th v-for="(po, pokey) in programme_outcomes" :key="('po' + pokey)">{{ po.code }}</th>
                <th v-for="(pso, psokey) in programme_specific_outcomes" :key="('pso' + psokey)">
                  {{ pso.code }}</th>
              </tr>
            </thead>

            <tbody v-for="(group, groupkey) in usersubgroups" :key="('group' + groupkey)">

              <tr>
                <td :rowspan="group.course_ids.length">{{ group.name }}</td>
                <template v-if="group.course_ids.length > 0" :course_id="group.course_ids[0]">
                  <td>{{ courses[group.course_ids[0]].name }}</td>
                  <td>
                    <span v-for="(co, cokey) in course_outcomes[group.course_ids[0]]" :key="('co' + cokey)">
                      {{ co.code }}({{ Math.round(course_co_attainments[group.course_ids[0]][co.id] * 100) / 100 }})
                      <br>
                    </span>
                  </td>

                  <td v-for="(po, pokey) in programme_outcomes" :key="('po1' + pokey)">
                    <span v-for="(co, cokey) in course_outcomes[group.course_ids[0]]" :key="('co1' + cokey)">
                      {{ coPoPsoMappingData[co.id].poMapping[po.id].level }}
                      <br>
                    </span>
                  </td>
                  <td v-for="(pso, psokey) in programme_specific_outcomes" :key="('pso1' + psokey)">
                    <span v-for="(co, cokey) in course_outcomes[group.course_ids[0]]" :key="('co2' + cokey)">
                      {{ coPoPsoMappingData[co.id].psoMapping[pso.id].level }}
                      <br>
                    </span>
                  </td>

                </template>
              </tr>
              <template v-for="course_id in group.course_ids">
                <tr v-if="(group.course_ids.indexOf(course_id) !== 0)" :key="'course' + course_id">
                  <td>{{ courses[course_id].name }}</td>
                  <td>
                    <span v-for="(co, cokey) in course_outcomes[course_id]" :key="('co' + cokey)">
                      {{ co.code }}({{ Math.round(course_co_attainments[course_id][co.id] * 100) / 100 }})
                      <br>
                    </span>
                  </td>

                  <td v-for="(po, pokey) in programme_outcomes" :key="('po1' + pokey)">
                    <span v-for="(co, cokey) in course_outcomes[course_id]" :key="('co1' + cokey)">
                      {{ coPoPsoMappingData[co.id].poMapping[po.id].level }}
                      <br>
                    </span>
                  </td>
                  <td v-for="(pso, psokey) in programme_specific_outcomes" :key="('pso1' + psokey)">
                    <span v-for="(co, cokey) in course_outcomes[course_id]" :key="('co2' + cokey)">
                      {{ coPoPsoMappingData[co.id].psoMapping[pso.id].level }}
                      <br>
                    </span>
                  </td>

                </tr>
              </template>

              <!-- <tr v-for="(course_id, course_id_key) in group.course_ids" :key="('course_ids' + course_id)">
                <td v-if="course_id_key !== 0" :rowspan="course_outcomes[course_id].length">{{
                    course_outcomes[course_id].name
                }}</td>
              </tr>

              <tr v-for="(course_id, course_id_key) in group.course_ids" :key="('course_ids' + course_id)">
                <td v-if="course_id_key !== 0" :rowspan="course_outcomes[course_id].length">{{
                    course_outcomes[course_id].name
                }}</td>
              </tr> -->

            </tbody>


          </table>
        </div>
      </b-col>
      <b-col v-if="getReportFlag">
        CO PO PSO Mapping Report.

        <div class="table-responsive">
          <table class="table table-bordered table-striped">
            <thead class="thead-dark text-left">
              <tr>
                <th rowspan="2">CO</th>
                <th :colspan="Object.keys(programme_outcomes).length">PO</th>
                <th :colspan="Object.keys(programme_specific_outcomes).length">PSO</th>
              </tr>
              <tr>
                <th v-for="(po, pokey) in programme_outcomes" :key="('po' + pokey)">{{ po.code }}</th>
                <th v-for="(pso, psokey) in programme_specific_outcomes" :key="('pso' + psokey)">
                  {{ pso.code }}</th>
              </tr>
            </thead>

            <tbody v-for="(course, coursekey) in courses" :key="('course' + coursekey)">
              <tr>
                <td class="text-center"
                  :colspan="(1 + Object.keys(programme_outcomes).length + Object.keys(programme_specific_outcomes).length)">
                  {{ course.name }} {{ course.code }}</td>
              </tr>
              <tr v-for="(co, cokey) in course_outcomes[course.id]" :key="('co' + cokey)">
                <td>{{ co.code }}</td>
                <td v-for="(po, pokey) in programme_outcomes" :key="('po' + pokey)">
                  {{ Math.round(coPoPsoMappingData[co.id].poMapping[po.id].value * 100) / 100 }}</td>
                <td v-for="(pso, psokey) in programme_specific_outcomes" :key="('pso' + psokey)">
                  {{ Math.round(coPoPsoMappingData[co.id].psoMapping[pso.id].value * 100) / 100 }}</td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <td>Avg. score</td>

                <td v-for="(po, pokey) in programme_outcomes" :key="('po' + pokey)">
                  {{ Math.round(po_pso_avg_mapping_scores.po_avg[po.id] * 100) / 100 }}</td>
                <td v-for="(pso, psokey) in programme_specific_outcomes" :key="('pso' + psokey)">
                  {{ Math.round(po_pso_avg_mapping_scores.pso_avg[pso.id] * 100) / 100 }}</td>
              </tr>
            </tbody>


          </table>
        </div>
      </b-col>
    </b-row>

  </div>
</template>

<script>
// import DatePicker from "vue2-datepicker";
export default {
  props: ["usersubgroupid"],
  async created() {
    (this.getConfigFlag = true),
      (this.setConfigFlag = true),
      (this.getDataFlag = true),
      this.getData();
  },
  components: {
    // DatePicker,
  },
  data() {
    return {
      getConfigFlag: true,
      setConfigFlag: false,
      getDataFlag: false,
      getReportFlag: false,

      fetchData: false,


      // get data responses <constant>
      coPoPsoMappingData: {},
      programme_specific_outcomes: [],
      programme_outcomes: [],
      course_co_attainments: {},
      course_outcomes: [],
      courses: [],
      usersubgroups: [],
      // + report response
      po_pso_avg_mapping_scores: {},
      //------

      //temp data for table creation 
      groupRowCount: {},
    };
  },
  methods: {
    getConfig() {
      // const url =
      //   this.$store.getters.getAPIKey.mainAPI + `/poattainment/getconfig`;
      // this.$axios
      //   .post(url, {
      //     subgroup: this.usersubgroupid,
      //     step: "co_po_pso_mapping",
      //   })
      //   .then((response) => {
      //     this.assementsArray = response.data;
      //   })
      //   .catch((error) => {
      //     this.$toast.error(error.response.data.message, {
      //       position: "top",
      //       duration: 3000,
      //     });
      //   });
    },
    async setConfig() {
      // const url =
      //   this.$store.getters.getAPIKey.mainAPI + `/poattainment/setconfig`;
      // await this.$axios
      //   .post(url, {
      //     subgroup: this.usersubgroupid,
      //     step: "co_po_pso_mapping",
      //   })
      //   .then((response) => {
      //     response;
      //   })
      //   .catch((error) => {
      //     this.$toast.error(error.response.data.message, {
      //       position: "top",
      //       duration: 3000,
      //     });
      //   });
    },
    async getData() {
      this.fetchData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/poattainment/getdata`;
      await this.$axios
        .post(url, {
          subgroup: this.usersubgroupid,
          step: "co_po_pso_mapping",
        })
        .then((response) => {
          let resData = response.data;

          this.coPoPsoMappingData = resData.coPoPsoMappingData;
          this.programme_specific_outcomes =
            resData.programme_specific_outcomes;
          this.programme_outcomes = resData.programme_outcomes;
          this.course_co_attainments = resData.course_co_attainments;
          this.course_outcomes = resData.course_outcomes;
          this.courses = resData.courses;
          this.usersubgroups = resData.usersubgroups;
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message, {
            position: "top",
            duration: 3000,
          });
        });

      //get subgroup courses for table structure
      for (const key in this.usersubgroups) {
        this.usersubgroups[key].course_ids = [];
        // this.groupRowCount[this.usersubgroups[key].id] = 0;
      }
      for (const key in this.courses) {
        let course = this.courses[key];
        this.usersubgroups[course.usersubgroup_id].course_ids.push(course.id);
        // this.groupRowCount[course.usersubgroup_id] += this.course_outcomes[course.id].length;
      }

      // await this.$forceUpdate();

      this.fetchData = false;
    },
    async getReport() {
      this.fetchData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/poattainment/report`;
      await this.$axios
        .post(url, {
          subgroup: this.usersubgroupid,
          step: "co_po_pso_mapping",
        })
        .then((response) => {
          let resData = response.data;

          this.coPoPsoMappingData = resData.coPoPsoMappingData;
          // this.programme_specific_outcomes = resData.programme_specific_outcomes;
          // this.programme_outcomes = resData.programme_outcomes;
          // this.course_co_attainments = resData.course_co_attainments;
          // this.course_outcomes = resData.course_outcomes;
          // this.courses = resData.courses;
          // this.usersubgroups = resData.usersubgroups;
          this.po_pso_avg_mapping_scores = resData.po_pso_avg_mapping_scores;

        })
        .catch((error) => {
          this.$toast.error(error.response.data.message, {
            position: "top",
            duration: 3000,
          });
        });
      this.fetchData = false;
      this.getReportFlag = true;
    },
  },
};
</script>
