<template>
  <div>
    <b-row>
      <b-col v-if="fetchData" style="font-size: 13px !important; color: red; text-align:center;">
        working on ...
      </b-col>
    </b-row>
    <b-row v-if="!fetchData">
      <b-col v-if="!setConfigFlag">
        PO/PSO configs.

        <div class="table-responsive">
          <table class="table table-bordered table-striped">
            <thead class="thead-dark text-left">
              <tr>
                <th>
                  <b-form-checkbox @change="selectAllPo" v-model="selectAllPoCheckbox">{{
                      selectAllPoCheckbox
                        ? "Unselect All"
                        : "Select All"
                  }}</b-form-checkbox>
                </th>
                <th>code</th>
                <th>programme outcome</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(po, subgroupIndex) in programme_outcomes" :key="subgroupIndex">
                <td class="align-middle">
                  <b-form-checkbox size="sm" v-model="programme_outcomes[subgroupIndex].checked">
                  </b-form-checkbox>
                </td>
                <td class="align-middle">
                  {{ po.code }}
                </td>
                <td class="align-middle">
                  {{ po.outcome }}
                </td>
              </tr>
            </tbody>
          </table>

        </div>
        <div class="table-responsive">
          <table class="table table-bordered table-striped">
            <thead class="thead-dark text-left">
              <tr>
                <th>
                  <b-form-checkbox @change="selectAllPso" v-model="selectAllPsoCheckbox">{{
                      selectAllPsoCheckbox
                        ? "Unselect All"
                        : "Select All"
                  }}</b-form-checkbox>
                </th>
                <th>code</th>
                <th>programme specific outcome</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(pso, subgroupIndex) in programme_specificoutcomes" :key="subgroupIndex">
                <td class="align-middle">
                  <b-form-checkbox size="sm" v-model="programme_specificoutcomes[subgroupIndex].checked">
                  </b-form-checkbox>
                </td>
                <td class="align-middle">
                  {{ pso.code }}
                </td>
                <td class="align-middle">
                  {{ pso.outcome }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-col>
      <b-col v-if="setConfigFlag">
        Confirm selection

        <div class="table-responsive">
          <table class="table table-bordered table-striped">
            <thead class="thead-dark text-left">
              <tr>
                <th>code</th>
                <th>programme outcome</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(po, subgroupIndex) in programme_outcomes" :key="subgroupIndex">
                <td v-if="po.checked" class="align-middle">
                  {{ po.code }}
                </td>
                <td v-if="po.checked" class="align-middle">
                  {{ po.outcome }}
                </td>
              </tr>
            </tbody>
          </table>

        </div>
        <div class="table-responsive">
          <table class="table table-bordered table-striped">
            <thead class="thead-dark text-left">
              <tr>
                <th>code</th>
                <th>programme specific outcome</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(pso, subgroupIndex) in programme_specificoutcomes" :key="subgroupIndex">
                <td v-if="pso.checked" class="align-middle">
                  {{ pso.code }}
                </td>
                <td v-if="pso.checked" class="align-middle">
                  {{ pso.outcome }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// import DatePicker from "vue2-datepicker";
export default {
  props: ["usersubgroupid"],
  async created() {
    this.fetchData = true;
    await this.getConfig();
    this.fetchData = false;
  },
  components: {
    // DatePicker,
  },
  data() {
    return {
      getConfigFlag: true,
      setConfigFlag: false,
      getDataFlag: false,
      getReportFlag: false,

      fetchData: false,
      programme_outcomes: [],
      programme_specificoutcomes: [],
      selectAllPoCheckbox: false,
      selectAllPsoCheckbox: false,
    };
  },
  methods: {
    selectAllPo() {
      this.programme_outcomes.forEach((po) => {
        po.checked = this.selectAllPoCheckbox;
      });

      this.$forceUpdate();
    },
    selectAllPso() {
      this.programme_specificoutcomes.forEach((pso) => {
        pso.checked = this.selectAllPsoCheckbox;
      });

      this.$forceUpdate();
    },
    async getConfig() {
      this.fetchData = true;
      const url =
        this.$store.getters.getAPIKey.mainAPI + `/poattainment/getconfig`;
      await this.$axios
        .post(url, {
          subgroup: this.usersubgroupid,
          step: "programmeoutcome",
        })
        .then((response) => {
          this.programme_outcomes = response.data.programme_outcomes;
          this.programme_outcomes.forEach((po) => {
            po.checked = false;
          });
          this.programme_specificoutcomes =
            response.data.programme_specificoutcomes;
          this.programme_specificoutcomes.forEach((pso) => {
            pso.checked = false;
          });
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message, {
            position: "top",
            duration: 3000,
          });
        });

      this.fetchData = false;
    },
    async setConfig() {
      this.fetchData = true;

      let programme_outcomes = [];
      await this.programme_outcomes.forEach((po) => {
        if (po.checked) programme_outcomes.push(po.id);
      });
      let programme_specificoutcomes = [];
      await this.programme_specificoutcomes.forEach((pso) => {
        if (pso.checked) programme_specificoutcomes.push(pso.id);
      });

      const url =
        this.$store.getters.getAPIKey.mainAPI + `/poattainment/setconfig`;
      await this.$axios
        .post(url, {
          subgroup: this.usersubgroupid,
          step: "programmeoutcome",
          programme_outcomes: programme_outcomes,
          programme_specificoutcomes: programme_specificoutcomes,
        })
        .then(() => {
          this.setConfigFlag = true;
          this.getDataFlag = true;
          this.getReportFlag = true;
        })
        .catch((error) => {
          this.$toast.error(error.response.data.message, {
            position: "top",
            duration: 3000,
          });
        });
      this.fetchData = false;
    },
    // async getData() {
    //   this.fetchData = true;
    //   const url =
    //     this.$store.getters.getAPIKey.mainAPI + `/poattainment/getdata`;
    //   await this.$axios
    //     .post(url, {
    //       subgroup: this.usersubgroupid,
    //       step: "programmeoutcome",
    //     })
    //     .then((response) => {
    //       this.assementsArray = response.data;
    //     })
    //     .catch((error) => {
    //       this.$toast.error(error.response.data.message, {
    //         position: "top",
    //         duration: 3000,
    //       });
    //     });
    //   this.fetchData = false;
    // },
    // async getReport() {
    //   this.fetchData = true;
    //   const url =
    //     this.$store.getters.getAPIKey.mainAPI + `/poattainment/report`;
    //   await this.$axios
    //     .post(url, {
    //       subgroup: this.usersubgroupid,
    //       step: "programmeoutcome",
    //     })
    //     .then((response) => {
    //       this.assementsArray = response.data;
    //     })
    //     .catch((error) => {
    //       this.$toast.error(error.response.data.message, {
    //         position: "top",
    //         duration: 3000,
    //       });
    //     });
    //   this.fetchData = false;
    // },
    // selectAllSubgroups() {},
  },
};
</script>
